






































































































































































































































import Component, { mixins } from "vue-class-component";
import WidgetMixins from "../../../mixins/WidgetMixins";
import DialogMixins from "../../../mixins/DialogMixins";
import { currencyFormat, loadView, loadWidget } from "@/utils/helpers";
import { namespace } from "vuex-class";
import { IOrders, IPaginate, IStore, IUser } from "@/types/types";
import timestamp from "@/filters/timestamp";
import mediumZoom from "medium-zoom";
import { Watch } from "vue-property-decorator";
import moment from "moment";

const productsModule = namespace("orders");
const authModule = namespace("auth");
@Component({
  name: "OrderIndex",
  components: {
    InternetConnection: loadWidget("pages/InternetConnection"),
    ToolBar: loadWidget("widgets/ToolBar"),
    SnackBar: loadWidget("widgets/SnackBar"),
    GetLinkDialog: loadView("pages/orders/dialogs/GetLink"),
    PackageXtrasDialog: loadView("pages/orders/dialogs/PackageXtras")
  },
  filters: {
    currencyFormat,
    timestamp
  }
})
export default class OrderIndex extends mixins(WidgetMixins, DialogMixins) {
  @productsModule.Getter("getAllProducts") orders!: IOrders[];
  @productsModule.Getter("getPagination") paginate!: IPaginate;
  @authModule.Getter("getUserDetails") details!: IUser & IStore;
  menu: boolean = false;

  dateRangeField: any = {
    startDate: false,
    endDate: false
  };
  dateRange: { startDate: string; endDate: string } = {
    startDate: "",
    endDate: ""
  };
  query: string = "";
  periods: Array<{ text: string; value: string }> = [
    {
      text: "Today",
      value: moment().format("YYYY-MM-DD")
    },
    {
      text: "3 Days Ago",
      value: moment()
        .subtract(3, "days")
        .format("YYYY-MM-DD")
    },
    {
      text: "1 Week",
      value: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    }
  ];
  selectedPeriod: any = null;
  headers: Array<{ text: string; value: string }> = [
    {
      text: "Date",
      value: "createdAt"
    },
    {
      text: "ORD. #",
      value: "orderNumber"
    },
    {
      text: "Contact No.",
      value: "contactNumber"
    },
    {
      text: "Package",
      value: "package"
    },
    {
      text: "Amt.",
      value: "totalAmount"
    },
    {
      text: "Payment",
      value: "isPaymentMade"
    },
    {
      text: "Status",
      value: "status"
    },
    {
      text: "Actions",
      value: "actions"
    }
  ];
  storeURL: string = "";
  orderDetails: Pick<IOrders, "_id"> = {
    _id: ""
  };

  options: any = {};
  pagination: IPaginate = {
    page: 1,
    total: 0,
    itemsPerPage: 15
  };

  @Watch("selectedPeriod")
  onPeriodChange(payload: any): void {
    if (!payload?.value) {
      this.$store.dispatch("orders/list", {
        page: this.pagination?.page,
        itemsPerPage: this.pagination?.itemsPerPage
      });
      return;
    }
    this.query = `&startDate=${payload?.value}`;
    this.$store.dispatch("orders/list", {
      page: this.pagination.page,
      itemsPerPage: this.pagination.itemsPerPage,
      query: this.query
    });
  }

  @Watch("dateRange", {
    deep: true
  })
  onDateRangeChange(date: { startDate: string; endDate: string }): void {
    if (moment(date?.startDate).isAfter(moment(date?.endDate))) {
      this.$store.dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: `Invalid date range provided`,
          status: "red"
        },
        { root: true }
      );
      return;
    }
    if (date?.startDate && date?.endDate) {
      this.query = `&startDate=${date?.startDate}&endDate=${date?.endDate}`;
      this.$store.dispatch("orders/list", {
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.query
      });
    }
  }

  @Watch("paginate")
  onPaginationSet(payload: IPaginate): void {
    this.pagination = { ...payload };
  }

  @Watch("options")
  onPageChange(options: IPaginate): void {
    this.$store.dispatch("orders/list", {
      page: options?.page,
      itemsPerPage: options?.itemsPerPage,
      query: this.query
    });
  }

  markAsCompleted(payload: { comment: string; status: string }) {
    this.$store.dispatch("orders/update", {
      body: payload,
      id: this.$store.getters["orders/getOrderDetails"]?._id
    });
  }

  created(): void {
    this.$store.dispatch("orders/list", { page: 1, itemsPerPage: 15 });
    setInterval(() => {
      if (this.$route.path === "/orders") {
        this.$store.dispatch("orders/list", {
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
          query: this.query
        });
      }
    }, 8000);

    this.$store.dispatch("auth/me");
  }

  mounted(): void {
    mediumZoom(".productPreview");
  }
}
